.x_panel {
  width: 100%;
  display: inline-block;
  -webkit-column-break-inside: avoid;
  opacity: 1;
  transition: all .2s ease;
  margin-bottom: 10px;
  position: relative;
}

.x_content {
  float: left;
  clear: both;
  margin-top: 5px;
  width: 100%;
  position: relative;
}

.table-responsive {
  min-height: .01%;
  overflow-x: auto;
}

.listing-table {
  border: 1px solid rgba(221,221,221,.78);
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
  display: table;
  text-indent: initial;
}

.listing-table-head {
  background: #475f87;
  color: #ECF0F1;
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.471;
}

.listing-table-head-column {
  border-top: 0;
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  padding: 8px;
  line-height: 1.42857143;
  text-align: center;
}

.listing-table-rows {
  background-color: #f9f9f9;
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}

.listing-table-column {
  padding: 12px;
  line-height: 0.42857143;
  display: table-cell;
  vertical-align: middle !important;
  border-top: 1px solid #ddd;
  font-family: "Helvetica Neue",Roboto,Arial,"Droid Sans",sans-serif;
  font-size: 12px;
  color: #000;
  font-weight: 400;
  text-align: center;
}

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.textUpper {
  text-transform: uppercase;
}

.listing-pagination {
  background: 0 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 119.8rem;
  padding: 0.5rem 3rem;
  margin: 0 auto;
  box-sizing: inherit;
  background: #ECF0F1;

  .inner {
    margin: 0 auto;
  }

  .listing-pagination-button {
    padding: 0 1em;
    display: inline-block;
    background-color: transparent;
    border: none;
    outline: 0
  }
}
