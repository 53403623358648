.top-nav {
  display: block;
  margin-left: 196px;
}

.nav_menu {
  position: static;
  float: left;
  background: #EDEDED;
  border-bottom: 1px solid #D9DEE4;
  margin-bottom: 10px;
  width: 100%;
}

.toggle {
  float: left;
  margin: 0;
  padding-top: 16px;
  width: 70px;
}

.nav {
  padding-left: 0;
  list-style: none;
}

.right-navbar {
  margin: 0;
  width: 70%;
  float: right!important;
  padding-left: 0;
  list-style: none;
}

.open {
  position: relative;
  display: inline-block;
  float: right;
}

.user-profile {
  color: #57b1b1 !important;
  font-weight: 500;
  line-height: 32px;
  margin-left: 0!important;
  padding: 13px 15px 12px;
  position: relative;
  display: block;
  text-decoration: none;
  background-color: transparent;
}

.user-profile-image {
  max-width: none;
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
  border: 0;
}

