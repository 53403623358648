.title_left {
  width: 80%;
  float: left;
  display: block;
  box-sizing: border-box;
  color: #73879C;
}

.page-title {
  width: 100%;
  height: 65px;
  padding: 10px 0;
}

.custom-class {
  padding: 0 6px;
  margin-bottom: 20px;
  margin-right: -10px;
  margin-left: -10px;
  box-sizing: border-box;
}

.filters-column {
  padding: 0 3px;
  margin-bottom: 10px;
  position: relative;
  min-height: 1px;
  float: left;
  width: 25%;
}

.filter-input {
  border-radius: 4px;
  width: 100%;
  display: block;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  outline: none;
  margin: 0;
}

.filter-footer {
  position: relative;
  min-height: 1px;
  float: left;
  padding-left: 10px;
  width: 100%;
  text-align: right;
}

.filter-footer-double {
  position: relative;
  min-height: 1px;
  float: left;
  padding-left: 10px;
  padding-top: 5px;
  width: 100%;
  text-align: right;
}

.filter-footer-header {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  width: 100%;
  text-align: right;
}

.filter-footer-button {
  border-radius: 3px;
  margin-bottom: 5px;
  margin-right: 2px;
  color: #fff;
  background-color: #475f87;
  display: inline-block;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}

.filter-button {
  border-radius: 3px;
  margin-bottom: 5px;
  color: #fff;
  background-color: #475f87;
  display: inline-block;
  padding: 6px 12px;
  font-weight: 600;
  font-size: 13px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
}

.bottom-spacing {
  padding-top: 180px;
}