.activity-main-container {
  width: 100%;
  background: #F7F7F7 !important;
}

.custom-sidebar-menu {
  min-height: 100%;
  width: 196px;
  position: absolute;
  display: flex;
  z-index: 1;
  float: left;
  background: #2A3F54;
  padding: 0 0;
}

.activities-right-col {
  margin-left: 196px !important;
  padding: 10px 20px 0;
}

.activities-row {
  padding-top: 10px;
  margin-right: -10px;
  margin-left: -10px;
}

.activities-header {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 7px;
  padding-left: 10px;
  width: 100%;
}